@mixin modal-fullscreen() {
  padding: 0 !important;

  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    border: 0 none;
    border-radius: 0;
  }
}

.modal-fullscreen-xs {
  @media (max-width:$grid-float-breakpoint) {
    @include modal-fullscreen;
  }
}

.setup-page {
  h3, h4 {
    margin-bottom: 20px;
    margin-top: 20px;
  }


  .table-controls {
    margin-bottom: 10px;
  }
  table {
    margin-bottom: 0px;
    a {
      cursor: pointer;
    }
    a + a {
      margin-left: 1em;
    }

    // Highlight clickable links on hover.
    tbody {
      tr {
        a {
          color: #bbb9b3;
        }
        &:hover {
          a {
            color: #4d4d4d;
            &:hover {
              color: #4bcdf0;
            }
          }
        }
        td {
          form {
            input[type="submit"] {
              background: none;
              border: 0;         
              color: #bbb9b3;
              &:hover {
                color: #4bcdf0;
              }     
            }
          }
        }
      }
    }
  }
}
