@import "_vertical_positioning.scss";
@import "_css_variables.scss";
////////////////////////////
// Bootstrap overrides /////
////////////////////////////
.btn-primary {
  @include button-variant(#fff, $button-blue, $button-blue);
}

////////////////////////////
// GENERAL /////////////////
////////////////////////////

body {
  background-color: var(--color-background);
  color: var(--color-black-80);
  font-family: $copy-font;
  font-size: 13px;
  min-width: 320px;
}
.btn-inverse {
  color: #fff;
}
.hidden {
  display: none;
}
i {
  cursor: pointer;
}
a {
  color: #4d4d4d;
}
a:hover,
a:focus {
  color: $link-hover;
  &:not(.text-underline) {
    text-decoration: none;
  }
}

.neg {
  color: $bad-change;
}
.pos {
  color: $good-change;
}

h1,
h2,
h3,
h4 {
  font-family: Open Sans, sans-serif;
  font-weight: 600;
}
h2,
h4 {
  font-size: 1.5em;
}
.col-no-padding {
  padding-left: 0;
  padding-right: 0;
}

.btn.btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  &.btn-sm {
    height: 30px;
    width: 30px;
  }
}

// Make container fluid with 1170px max-width
@media screen {
  .container {
    width: 100%;
    max-width: 1170px;
  }
}

#app {
  display: flex;
  width: 100%;
  padding-bottom: var(--sidenav-iconbar-width);

  @media (min-width: $grid-float-breakpoint) {
    padding-bottom: 0;
  }

  @media screen {
    padding-top: var(--header-height);
  }

  .main-column {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &.flyover-filters-open {
    .hide-flyover-filters-open {
      display: none;
    }
  }
}

#main {
  padding-top: 15px;
  display: flex;
  justify-content: center;
  &.impersonate {
    background-color: red;
  }
}

.centered {
  text-align: center;
}

.intercom-lightweight-app {
  // Hide behing modal backdrop
  z-index: 1030 !important;
}

@import "_typography";
@import "_colors";
@import "_headers";
@import "_filters";
@import "_dashboard";
@import "_forms";
@import "_reports";
@import "_other";
@import "_print";
@import "_crosstab";
@import "_data_table";
@import "_highcharts";
@import "scrollbar";
@import "component";
@import "components/survey";
@import "components/adm_msg";
@import "components/tabs";
@import "components/buttons";
@import "components/modal";
@import "components/spinner";
@import "components/file_upload";
@import "components/filters";
@import "components/filter_set";
@import "components/pill_select";
@import "components/dropdown";
@import "components/chart_options";
@import "components/custom_report_editor";
@import "components/permissions_editor";
@import "components/range_filter";
@import "components/zoined_pill_selector";
@import "components/basket_scatter";
@import "main/filters/zoined-calendar";
@import "setup/common";
@import "setup/newsletters";
@import "setup/newsletter_editor";
@import "setup/users";
@import "setup/budget";
@import "setup/settings";
@import "setup/teams";
@import "icon-zoined";
@import "icon-weather";
@import "_new-layout";
@import "_public";
@import "utils";
